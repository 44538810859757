import { signIn } from "next-auth/react";
import Image from "next/image";
import { useRouter } from "next/router";
import { FormEventHandler, MouseEventHandler, useState } from "react";
import { LogIn } from "react-feather";
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Row } from "reactstrap";
import GoogleLogo from "../../public/google.svg";

export default function AuthForm() {
  const [userInfo, setUserInfo] = useState({ email: "", password: "" });
  const router = useRouter();

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    // validate your userinfo
    e.preventDefault();

    const res = await signIn("credentials", {
      email: userInfo.email,
      password: userInfo.password,
      redirect: false,
    });

    router.replace("/");
  };

  const handleGoogleOAuth: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();

    await signIn("google", { callbackUrl: "/" });
  };

  return (
    <section className="loginBox">
      <Container fluid className="h-100">
        <Row className="justify-content-center align-items-center h-100">
          <Col lg="12" className="loginContainer">
            {/* <AuthLogo /> */}
            <Card>
              <CardBody className="p-4 m-1">
                <h4 className="mb-0 fw-bold">Login</h4>
                <Form onSubmit={handleSubmit}>
                  <Label htmlFor="email" className="mt-3">
                    Email
                  </Label>
                  <Input
                    value={userInfo.email}
                    onChange={({ target }) => setUserInfo({ ...userInfo, email: target.value })}
                    type="email"
                    placeholder="john@email.com"
                  />
                  <Label htmlFor="password" className="mt-3">
                    Password
                  </Label>
                  <Input
                    value={userInfo.password}
                    onChange={({ target }) => setUserInfo({ ...userInfo, password: target.value })}
                    type="password"
                    placeholder="********"
                  />
                  <div className="mt-4">
                    <LogIn width={32} height={32} />
                    &nbsp;
                    <Button type="submit" color="primary">
                      Login with Email
                    </Button>
                  </div>
                </Form>
                <div className="mt-4">
                  <Image src={GoogleLogo} alt="google" width={32} height={32} />
                  &nbsp;
                  <Button color="primary" onClick={handleGoogleOAuth}>
                    Login with Google
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
