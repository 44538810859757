import Head from "next/head";
import type { NextPage } from "next";
import Layout from '../../components/layouts/blank';
import AuthForm from '../../components/auth/authform';

const SignIn: NextPage = () => {
  return (
    <Layout>
      <Head>
        <title>Sign In</title>
      </Head>
      <section>
        <AuthForm/>
      </section>
    </Layout>
  )
};

export default SignIn;